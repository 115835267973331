import React from "react";
import { Typography, Box } from "@mui/material";
import { BoxContainer, HeroContainer } from "./styles/Container.Styled";
import YoutubeEmbed from "./YoutubeEmbed";
import ScrollToTop from "./ScrollToTop";
import Navigation from "./Main_Nav";

function Home() {
  return (
    <>
    <ScrollToTop />
    <Navigation />
    <HeroContainer className="connectContainer bio-container">
    </HeroContainer>
    <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          backgroundColor: "transparent",
        }}
      >
    <BoxContainer >
      <Typography color="#FFF"
      variant="h1"
      align="center"
      letterSpacing={1.6}
      fontFamily= "Mosthat"
      
      pb={5}
      pt={5}
      pr={10}
      pl={10}>
      A.T.Z. · DIONYSUS
      </Typography>
      <BoxContainer>
        <Typography pr={5} pl={5}>
      <YoutubeEmbed embedId="Fu1ga41QD3Q" />
      </Typography>
      </BoxContainer>
      {/* <Typography color="#FFF"
      fontSize={30}
      align="center"
      letterSpacing={1.6}
      fontFamily= "Mosthat"
      pt={5}
      pr={10}
      pl={10}>
      FROM THE UPCOMING ALBUM
      </Typography> */}
      {/* <Typography color="red"
      variant="h1"
      align="center"
      letterSpacing={1.6}
      fontFamily= "Mosthat"
      pb={5}
      pr={10}
      pl={10}>
      BLUD RED
      </Typography> */}
    </BoxContainer>
    
    
    </Box>

    </>
  );
}

export default Home;