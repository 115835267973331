import { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import Navigation from "./Main_Nav";
import ScrollToTop from "../components/ScrollToTop";
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from "axios"
import { HeroContainer } from "./styles/Container.Styled";




export default function Contact() {


  const url = "https://api.atz333.com/api/contact/userContact";

  const resetForm = () => {
    setName("")
    setEmail("")
    setMessage("")
    
  }

  const successMessage = () => {
    setSuccess(true);
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!name || !email || !message) return;

    axios.post(url,
      {
        name, 
        email,
        message,
        
        
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (e) {
        console.log("Error is here axios call ====>", e);
      });

      resetForm();
      successMessage();
 
  };

 

  return (
    <>
      <ScrollToTop />
      <Navigation />
      <HeroContainer className="connectContainer contact-container"></HeroContainer>
      
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          paddingBottom: "100px",
          paddingTop: "150px",
          paddingRight: "20px",
          paddingLeft: "20px",
          marginTop: 9,
        }}
      >
        <Box sx={{ maxWidth: 600, p: 2, backgroundColor: "#EFF3F7", opacity: 0.8, borderRadius: 2, }}>
          <Typography
            lineHeight={1.6}
            fontFamily="Mosthat"
            variant="h2"
            align="center"
            mb={2}
            pt={5}
            
          >
            A.T.Z. MGMT
          </Typography>

          <form onSubmit={handleSubmit}>
            
            <TextField
              helperText="Please enter your name"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
              required
            />
            <TextField
              helperText="Please enter your email"
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              required
              type="email"
            />
            <TextField
              helperText="Please enter your message"
              fullWidth
              label="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              margin="normal"
              required
              multiline
              rows={8}
            />
            <Typography pt={2} pb={2}>
          { success ? <Alert icon={<CheckCircleOutlineIcon fontSize="inherit" />} severity="success">
        Your message has been received! Thank you
      </Alert> : null
}
          </Typography>
            <Button variant="contained" type="submit" sx={{ mt: 2 }}>
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
}

