import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import LogoGif from "../videos/atz_spinning_coin.gif"
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from "./Sidebar";


function Navigation() {
  const [sidebar, setSidebar] = useState(false)
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand navbar-dark nav-bar-bg-color font-face-Mosthat">
        <div className="container">
          <NavLink className="navbar-brand" to="/home">
            <img src={LogoGif} className="navbar_logo" alt="logo" />
            
          </NavLink>
          <div className="navlinks_container">
            <ul className="navbar-nav ml-auto">

              <li className="nav-item">
                <a className="nav-link" href="https://hypeddit.com/atz-dionysus">
                  MUSIC
                </a>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/merch">
                  MERCH
                </NavLink>
              </li>
              <li className="nav-item">
              <NavLink className="nav-link" to="/tour">
              TOUR
              </NavLink>
              
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/connect">
                 CONNECT
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="burger_icon" onClick={()=> setSidebar(true)}> 
          <MenuIcon sx={{fontSize: 30}} />
          </div>
        </div>
      </nav>
      <Sidebar open={sidebar} onClose={()=> setSidebar(false)} />
    </div>
  );
}

export default Navigation;