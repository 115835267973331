import React from "react";
import Drawer from "@mui/material/Drawer";
import { useNavigate, Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import LogoGif from "../videos/atz_spinning_coin.gif"

const navItems = ["merch", "tour", "connect", "contact" ];
const Sidebar = ({ open, onClose }) => {
  const navigation = useNavigate();
  return (
    <Drawer
      anchor={"top"}
      open={open}
      onClose={onClose}
      transitionDuration={500}
      sx={{
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
          backgroundColor: "black"
        },
      }}
    >
      <img src={LogoGif} onClick={() => navigation("/home")} className="sidebar_logo" alt="spinning coin"/>
      <CloseIcon onClick={onClose} className="sidebar_closeIcon" />
      <div className="sidebar_links_container">
      <div
            className="sidebar_links"
          >
            <Link to={"https://hypeddit.com/atz-dionysus"} target="_blank" className="remove-underline labelTxt">Music</Link>
          </div>
        {navItems.map((item, i) => (
          <div
            className="sidebar_links"
            key={item}
            onClick={() => navigation(`/${item}`)}
          >
            {item}
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default Sidebar;
