import { createGlobalStyle } from "styled-components";


const GlobalStyles = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');


* {
  box-sizing: border
}

html {
  height: 100%
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: #000;
  cursor: url('images/spiral.svg'),auto;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

a {
  cursor: url('images/spiral.svg'),auto;
  font-family: "Mosthat";
  color: red;
  
}

a:hover {
  color: #FFF;
  transition: ease background-color 250ms;
}






`

export default GlobalStyles;