import React from "react";
import LogoGif from "../videos/atz_spinning_coin.gif";
import { useNavigate } from "react-router-dom";

function Landing() {
  const navigate = useNavigate();
  return (
    <>
      <div class="center-image"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={LogoGif}
          alt="spinning coin"
          onClick={() => navigate("/home")}
        ></img>
      </div>
    </>
  );
}

export default Landing;
