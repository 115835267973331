import { TextField } from "@mui/material";
import styled from "styled-components";

export const HeroContainer = styled.div`
  color: #eff3f7;
  max-width: 100%;
`;

export const BoxContainer = styled.div`
  max-width: 1240px;
  margin-top: 50px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  
  
`;

export const WidgetContainer = styled.div`
  max-width: 1240px;
  margin-top: 50px;
  padding-top: 250px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

`;

export const ConnectBox = styled.div`
  max-width: 100%;
  padding-top: 350px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;


  @media (max-width: 991px) {
    max-width: 100%;
    padding-top: 75px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

`;

export const StoreContainer = styled.div`
  max-width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;


`;

export const ConnectContainer = styled.div`
  max-width: 1240px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 150px;
  justify-content: space-between;
  padding-bottom: 50px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  z-index: 1;
`;

export const FooterContainer = styled.div`
  max-width: 600px;
  justify-content: space-between;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  z-index: 1;
`;

export const LinksContainer = styled.div`
  
  height: 100%;
  padding-top: 40px;
  font-family: Mosthat;
  font-size: 40px;
  text-align: center;
  z-index: 2;
`;


export const MerchContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  text-align: center;
  justify-content: center;
  padding: 50px;
  font-size: 20px;
  font-weight: 700;
`;

export const SubButtonContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 10px;
  align-items: center;
`;


export const StyledTextField = styled(TextField)`
  & label.Mui-focused {
    color: #eff3f7;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #eff3f7;
    }
    &:hover fieldset {
      border-color: #ced6e0;
    }
    &.Mui-focused fieldset {
      border-color: #eff3f7;
    }
  }
`;
