import * as React from "react";
import { Typography, Grid } from "@mui/material";
// import Link from "@mui/material/Link";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
import { BoxContainer, FooterContainer } from "./styles/Container.Styled";
import { useLocation } from "react-router-dom";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import BackHandIcon from '@mui/icons-material/BackHand';
import YouTubeIcon from "@mui/icons-material/YouTube";
import PhlokkLogo from "../images/Phlokk_logo_sm.svg";
import PatronLogo from "../images/patron_icon_sm.svg";
import TiktokLogo from "../images/tiktok_sm.svg";

function Footer() {
  const { pathname } = useLocation();
  if (pathname === "/" || pathname === "/connect" || pathname === "/contact") return null;

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "transparent",
        p: 6,
      }}
    >
      <BoxContainer align={"center"}>
      <FooterContainer>
            <Grid
              letterSpacing="1.2px"
              container
              rowSpacing={1}
              columnSpacing={0}
            >
              <Grid item xs={4} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"}>
                  <a
                    href="https://hypeddit.com/atz-dionysus"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AudiotrackIcon sx={{ fontSize: 25 }} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"}>
                  <a
                    href=" https://www.youtube.com/channel/UCiiKgd1YLJ35zcGMIOW9aFg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YouTubeIcon sx={{ fontSize: 25 }} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"}>
                  <a
                    href="https://www.instagram.com/alexanderthezealous"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram sx={{ fontSize: 25 }} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"}>
                  <a
                    href="https://www.facebook.com/alexanderthezealous"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook sx={{ fontSize: 25 }} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"}>
                  <a href="https://phlokk.com" target="_blank" rel="noreferrer">
                    <img src={PhlokkLogo} alt="Phlokk.com logo"></img>
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"}>
                  <a
                    href="https://www.tiktok.com/@alexanderthezealous"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TiktokLogo} alt="TikTok icon"></img>
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"}>
                  <a
                    href="https://twitter.com/FIBONACCIDEMI"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Twitter sx={{ fontSize: 25 }} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"}>
                  <a
                    href="https://www.patreon.com/ATZ333"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={PatronLogo} alt="Patron logo"></img>
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"} pb={5}>
                  <a
                    href="https://www.clubhouse.com/@atz?utm_medium=ch_profile&utm_campaign=Rb-siSVM2oNotIlPcvGiyg-860067"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BackHandIcon sx={{ fontSize: 25 }} />
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </FooterContainer>
        <Typography
          pt={2}
          fontSize={18}
          color="#FFF"
          align="center"
          fontFamily={"Mosthat"}
        >
          ALL RIGHTS RESERVED &copy; A.T.Z. {new Date().getFullYear()}
          {"."}
        </Typography>
        <Typography
          fontSize={16}
          color="#FFF"
          align="center"
          fontFamily={"Mosthat"}
        >
          DESIGNED BY{" "}
          <a
            className="remove-underline phlokkdesign_footer"
            href="https://phlokkdesign.com"
          >
            PHLOKKDESIGN
          </a>
        </Typography>
      </BoxContainer>
    </Box>
  );
}

export default Footer;
