import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import {
  BoxContainer,
  MerchContainer,
  StoreContainer,
} from "./styles/Container.Styled";
import tank from "../images/tank.png";
import tshirt from "../images/tshirt.png";
import hood from "../images/hood.png";

function Store() {
  return (
    <>
      <BoxContainer>
        <MerchContainer>
        <Typography
                textTransform={"uppercase"}
                  align="center"
                  letterSpacing={1.6}
                  fontFamily="Mosthat"
                  variant="h6"
                  pt={5}
                >
                  <b>U.S.A. ONLY &#8226; FREE SHIPPING</b>
                </Typography>
          <Grid
          sx={{paddingTop: 15}}
            letterSpacing="1.2px"
            container
            rowSpacing={5}
            columnSpacing={25}
          >
            <Grid item xs={12} sm={4}>
              <Typography align="center">
                <img style={{ width: "175px", borderRadius: 5, }} src={tank} alt="album" />
              </Typography>

              <StoreContainer>
                <Typography
                textTransform={"uppercase"}
                  align="center"
                  letterSpacing={1.6}
                  fontFamily="Mosthat"
                  variant="h4"
                  pt={5}
                >
                  <b>DRACHM TANK</b>
                </Typography>
                <Typography pt={3} textTransform={"uppercase"}
                  align="center"
                  letterSpacing={1.6}
                  fontFamily="Mosthat"
                  variant="h4"
                  p={2}>
                  <b>$36.90</b>
                </Typography>
                <Typography pt={5} pb={10}>
                  <Button
                    disableRipple
                    href="https://buy.stripe.com/00gaHQ6akf61gVOdQR"
                    color="secondary"
                    target="_blank"
                    variant="outlined"
                    p={2}
                  >
                    <b>Buy Now</b>
                  </Button>
                </Typography>
              </StoreContainer>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="center">
                <img style={{ width: "175px", borderRadius: 5, }} src={tshirt} alt="shirts" />
              </Typography>
              <Typography
              textTransform={"uppercase"}
                align="center"
                letterSpacing={1.6}
                fontFamily="Mosthat"
                variant="h4"
                pt={5}
              >
                <b>DRACHM T</b>
              </Typography>
              <Typography pt={3} textTransform={"uppercase"}
                  align="center"
                  letterSpacing={1.6}
                  fontFamily="Mosthat"
                  variant="h4"
                  p={2}>
                <b>$44.44</b>
              </Typography>
              <Typography pt={5} pb={10}>
                <Button
                  disableRipple
                  href=" https://buy.stripe.com/14kaHQfKUaPL492cMP"
                  color="secondary"
                  target="_blank"
                  variant="outlined"
                  p={2}
                >
                  <b>Buy Now</b>
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="center">
                <img style={{ width: "175px", borderRadius: 5, }} src={hood} alt="Atz hood" />
              </Typography>

              <Typography
              textTransform={"uppercase"}
                align="center"
                letterSpacing={1.6}
                fontFamily="Mosthat"
                variant="h4"
                pt={5}
              >
                <b>DRACHM HOOD</b>
              </Typography>
              <Typography pt={3} textTransform={"uppercase"}
                  align="center"
                  letterSpacing={1.6}
                  fontFamily="Mosthat"
                  variant="h4"
                  p={2}>
                <b>$63.90</b>
              </Typography>
              <Typography pt={5} pb={10}>
                <Button
                  disableRipple
                  href="https://buy.stripe.com/9AQbLU2Y82jf8pi7sw"
                  color="secondary"
                  target="_blank"
                  variant="outlined"
                  p={2}
                >
                  <b>Buy Now</b>
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </MerchContainer>
        </BoxContainer>

    </>
  );
}

export default Store;
