import React, { useState } from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Alert from "@mui/material/Alert";
import axios from "axios";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import BackHandIcon from '@mui/icons-material/BackHand';
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  HeroContainer,
  StyledTextField,
  ConnectContainer,
  LinksContainer,
  ConnectBox,
  BoxContainer,
} from "./styles/Container.Styled";
import Navigation from "./Main_Nav";
import PhlokkLogo from "../images/Phlokk_logo_md.svg";
import PatronLogo from "../images/patron_icon.svg";
import TiktokLogo from "../images/tiktok.svg";
import ScrollToTop from "./ScrollToTop";

function Connect() {

  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  const url = "https://api.atz333.com/api/subscribe/subscriberData";

  const resetForm = () => {
    setEmail("");
  };

  const successMessage = () => {
    setSuccess(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) return;

    axios
      .post(url, {
        email,
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (e) {
        console.log("Error is here axios call ====>", e);
      });

    resetForm();
    successMessage();
  };

  return (
    <>
      <ScrollToTop />
      <Navigation />
      <HeroContainer className="connectContainer dark-container"></HeroContainer>
      <ConnectBox>
         <LinksContainer>
          <ConnectContainer>
            <Grid
              letterSpacing="1.2px"
              container
              rowSpacing={2}
              columnSpacing={3}
            >
              <Grid item xs={12} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"} pb={5}>
                  <a
                    href="https://hypeddit.com/atz-dionysus"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AudiotrackIcon sx={{ fontSize: 35 }} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"} pb={5}>
                  <a
                    href=" https://www.youtube.com/channel/UCiiKgd1YLJ35zcGMIOW9aFg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YouTubeIcon sx={{ fontSize: 35 }} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"} pb={5}>
                  <a
                    href="https://www.instagram.com/alexanderthezealous"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram sx={{ fontSize: 35 }} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"} pb={5}>
                  <a
                    href="https://www.facebook.com/alexanderthezealous"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook sx={{ fontSize: 35 }} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"} pb={5}>
                  <a href="https://phlokk.com" target="_blank" rel="noreferrer">
                    <img src={PhlokkLogo} alt="Phlokk.com logo"></img>
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"} pb={5}>
                  <a
                    href="https://www.tiktok.com/@alexanderthezealous"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TiktokLogo} alt="TikTok icon"></img>
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"} pb={5}>
                  <a
                    href="https://twitter.com/FIBONACCIDEMI"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Twitter sx={{ fontSize: 35 }} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"} pb={5}>
                  <a
                    href="https://www.patreon.com/ATZ333"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={PatronLogo} alt="Patron logo"></img>
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="#FFF" fontFamily={"Mosthat"} pb={5}>
                  <a
                    href="https://www.clubhouse.com/@atz?utm_medium=ch_profile&utm_campaign=Rb-siSVM2oNotIlPcvGiyg-860067"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BackHandIcon sx={{ fontSize: 35 }} />
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </ConnectContainer>
          <BoxContainer>
            <Typography fontFamily={"Mosthat"} pl={15} pr={15} pb={5} pt={2}>
              {success ? (
                <Alert
                  icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                  severity="success"
                >
                  You are now subscribed!
                </Alert>
              ) : null}
            </Typography>
          </BoxContainer>
          
          <Typography
            color="#FFF"
            fontFamily={"Mosthat"}
            variant="h3"
            pb={5}
          >
            SUBSCRIBE TO A.T.Z.
          </Typography>
          <Box sx={{ paddingBottom: 25 }}>
          <form onSubmit={handleSubmit}>
            <StyledTextField
              sx={{paddingRight: 2.5, marginTop: 1.7,}}
              id="outlined-basic"
              variant="outlined"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{
                style: { color: "white", height: 8.5,  },
              }}
            ></StyledTextField>
            <Button
              variant="outlined"
              startIcon={<MailOutlineIcon />}
              color="secondary"
              type="submit"
              size="large"
            >
              Submit
            </Button>
          </form>
          </Box>
        </LinksContainer>
      </ConnectBox>
    </>
  );
}

export default Connect;
