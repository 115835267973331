import React from "react";
import { Box } from "@mui/material";
import { HeroContainer } from "./styles/Container.Styled";
import Store from "../components/Store";
import ScrollToTop from "./ScrollToTop";
import Navigation from "./Main_Nav";

function Home() {
  return (
    <>
<ScrollToTop />
<Navigation />

      <HeroContainer className="connectContainer landingHero"></HeroContainer>
      <Box
        sx={{
          width: "100%",
          color: "#EFF3F7",
          pt: 9.3,
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
      >
        <Store />
      </Box>
    </>
  );
}

export default Home;
