import { ThemeProvider } from "@mui/system";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "../src/fonts/Butler_Black_Stencil.otf";
import { BrowserRouter as Router, Route, Routes,  } from "react-router-dom";
import { Footer, Landing, Home, Contact, Merch, Tour, Connect } from "./components";
import GlobalStyles from "./components/styles/Global"
import theme from "../src/theme"

const root = createRoot(document.getElementById("root"));

root.render(
  <Router>
    <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/home" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/merch" element={<Merch />} />
      <Route path="/connect" element={<Connect />} />
      <Route path="/tour" element={<Tour />} />
    </Routes>
    <Footer />
    </ThemeProvider>
  </Router>
);
