import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// hit control + spacebar to see all list of things to override on components

// add disableRipple as prop to btn so it don't animate a ripple effect


export const Colors = {
  primary: "#000",
  secondary: "#EFF3F7",
}

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
          disableRipple: true,
          
      },
    },
  },
});

export default responsiveFontSizes(theme);